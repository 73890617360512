import React from 'react'
import '../Assets/Styles/Login.css'
import { Button } from 'react-bootstrap'
import ZohoMailLogo from '../Assets/Images/ZohoMailLogo.png';
import GmailLogo from '../Assets/Images/GmailLogo.png'
// import '../Screens/Authentication/auth.css'
export const Login = () => {
      const ZohoAthUrl = localStorage.getItem("ZohoAuthURL");
      const GoogleAthUrl = localStorage.getItem("GoogleAuthURL")
      return (
            <div className='AuthBody'>
                  <div className='InnerAuthBody ZohoAuthBody'>
                        <img src={ZohoMailLogo} alt="Zoho Logo" className='zohomailLogo logo' />
                        <h3>Login With Zoho</h3>
                        <p>Click below to login with your Zoho Suite</p>
                        <div className='LoginButton'>
                              <a
                                    href={ZohoAthUrl}
                                    className=""
                                    onClick={() => localStorage.setItem("MailStatus", "ZohoMail")}
                              >
                                    <Button className="btn btn-success">
                                          {" "}
                                          Login
                                    </Button>
                              </a>
                        </div>

                  </div>
                  <h6>OR</h6>
                  <div className='InnerAuthBody GoogleAuthBody'>
                        <img src={GmailLogo} alt="Gmail Logo" className='gmailLogo logo' />
                        <h3>Login With Google</h3>
                        <p>Click below to login with your Google Account</p>
                        <div className='LoginButton'>

                              <a
                                    href={GoogleAthUrl}
                                    className=""
                                    onClick={() => localStorage.setItem("MailStatus", "GoogleMail")}

                              >
                                    <Button className="btn btn-success">
                                          {" "}
                                          Login
                                    </Button>
                              </a>
                        </div>

                  </div>

            </div>
      )
}
