import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import Main from './Screens/main';
function App() {
  // const [Authenticated, setAuthenticated] = useState(false)


  return (

    <div className="App">
      <Main />


    </div>
  );
}

export default App;
