import React from 'react'
import { Spinner } from "react-bootstrap";
import '../Assets/Styles/Loader.css';
export const Loader = () => {
  return (
    <div className="d-flex align-items-center justify-content-center h-100 loaderDiv">
      <Spinner className="spinner-style" animation="grow" variant="success" />
    </div>
  )
}
