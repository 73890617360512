import React, { useEffect, useState } from "react";
import { Login } from "./Login";
import { DecryptVal } from "../utils/Encryption";
import Validatecode from "./Authentication/validatecode";
import { Loader } from "../Components/Loader";
import { RetrieveAuthURL } from "../utils/apicalls";
const Main = () => {
  const [ShowLogin, setShowLogin] = useState(false);
  const [Loading, setLoading] = useState(true);
  const [Validate_Code, setValidateCode] = useState(false);
  const [code, setcode] = useState("");
  const [email, setEmail] = useState('');
  useEffect(() => {
    initiateWidget()
  }, []);

  const initiateWidget = async () => {


    const urlParams = new URLSearchParams(window.location.search);
    var LoginStatus = urlParams.get("UEM");
    var codeStatus = urlParams.get("code");
    if (LoginStatus) {
      setValidateCode(false);
      localStorage.setItem("UEM", DecryptVal(LoginStatus));
      InitiateOrg();

    } else if (codeStatus) {
      setcode(codeStatus);
      setEmail(localStorage.getItem("UEM"));
      setValidateCode(true);
      setShowLogin(false);
      setLoading(false);

    }
    else {
      setShowLogin(false);
      setValidateCode(false);
      setLoading(false);
    }
  }

  const InitiateOrg = () => {

    let authURL = RetrieveAuthURL();
    authURL.then((resp) => {
      if (resp.Status === "Success") {
        localStorage.setItem("ZohoAuthURL", resp.Authorization_URL);
        localStorage.setItem("GoogleAuthURL", resp.Googles_AuthURL);
        setShowLogin(true);
        setLoading(false);
      }
    }).catch((err) => {

    })



  };


  return (
    <>
      {Loading ? (
        <div style={{ height: "inherit" }} className="d-flex justify-content-center align-center"> <Loader /></div>
      ) : ShowLogin ? (
        <Login />
      ) : Validate_Code ? (
        <Validatecode code={code} email = {email}/>
      ) : (
        <></>
      )}
    </>
  );
};

export default Main;
