import { GettingToken, RetrieveAuthUrlEndPoint } from "./endpoints";

const postAUth=(Body,Endpoint)=>{
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    
    var raw = JSON.stringify(Body);
    
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    
    return fetch(Endpoint, requestOptions)
      .then(response => response.json())
      .then(result => { return result})
      .catch(error => {return null});
}
const getCall = (Endpoint) => {
  // var myHeaders = new Headers();

  var requestOptions = {
    method: "GET",
    redirect: "follow",
  };

  return fetch(Endpoint, requestOptions)
    .then((response) => response.json())
    .then((result) => {
      return result;
    })
    .catch((error) => {
      return null;
    });
};
export const AssociateToken=(Body)=>{

    return postAUth(Body, GettingToken)
}
export const RetrieveAuthURL = () => {
  return getCall(RetrieveAuthUrlEndPoint);
}