import React, { useEffect, useState } from 'react'
import { Loader } from '../../Components/Loader'
import { AssociateToken } from '../../utils/apicalls'

const Validatecode = (props) => {
    const [Loading, setLoading] = useState(true)
    const [ValidateSuccess, setValidateSuccess] = useState(false)
    const [timeLeft, setTimeLeft] = useState(null);
    useEffect(() => {

        setTimeLeft(7)
        var mailStatus = localStorage.getItem("MailStatus");
        var code = props.code
        var userEmail = localStorage.getItem("UEM");
        if (!userEmail) {
            userEmail = props.email;
        }
        if (mailStatus) {
            var obj = null;
            if (mailStatus === "ZohoMail") {
                obj = {
                    MailBox_Code: code,
                    MailBox_Type: "Zoho",
                    User_Email: userEmail
                }
            }
            else if (mailStatus === "GoogleMail") {
                obj = {
                    MailBox_Code: code,
                    MailBox_Type: "Gmail",
                    User_Email: userEmail
                }
            }
            if (obj) {

                var resp = AssociateToken(obj)
                resp.then((data) => {
                    if (data !== null) {
                        localStorage.clear();
                        if (data.Status === "Error") {
                            setValidateSuccess(false)
                        }
                        else {
                            setValidateSuccess(true)
                        }
                    }
                })
            }
            setTimeout(() => {
                setLoading(false)
            }, 2000);
        }




        setTimeout(() => {

            // window.open('','_self').close();
        }, 7000);
    }, [])


    return (

        <div className='AuthBody'>
            {Loading ? <div><Loader />
                <h5> Loading, please wait...</h5>
            </div> :
                ValidateSuccess ?
                    <div className='Loader flex-column d-flex'>
                        <dotlottie-player src="https://lottie.host/a904c64d-0b24-461b-8e4b-903b20ca4785/QGuRArnWiJ.json" background="transparent" speed="1" style={{ width: 250, height: 250 }} autoplay></dotlottie-player>

                        <h5>Account authorized successfuly</h5>
                        <p>You can go back to the widget and click "Validate Authorization". This page will automaicaly close in {timeLeft} seconds</p>
                    </div>
                    :
                    <div className='Loader flex-column'>
                        <dotlottie-player src="https://lottie.host/a8a1e6bc-1ab7-415c-a463-5c0494c1039c/PpyJSDIoL5.json" background="transparent" speed="1" style={{ width: "300px", height: "300px" }} autoplay></dotlottie-player>

                        <h5>Something Went Wrong</h5>
                        <p>Please go back to the widget and authorize again.</p>
                    </div>
            }
        </div>
    )
}

export default Validatecode